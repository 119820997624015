import addLanguages from './languages'

// Private helper vars
var lang = /\blang(?:uage)?-(\w+)\b/i
var uniqueId = 0

var Prism = {}
var _ = Prism = {
  manual: Prism && Prism.manual,
  util: {
    encode: function (tokens) {
      if (tokens instanceof Token) {
        return new Token(tokens.type, _.util.encode(tokens.content), tokens.alias)
      } else if (_.util.type(tokens) === 'Array') {
        return tokens.map(_.util.encode)
      } else {
        return tokens.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/\u00a0/g, ' ')
      }
    },

    type: function (o) {
      return Object.prototype.toString.call(o).slice(8, -1)
    },

    objId: function (obj) {
      if (!obj['__id']) {
        Object.defineProperty(obj, '__id', { value: ++uniqueId })
      }
      return obj['__id']
    },

    // Deep clone a language definition (e.g. to extend it)
    clone: function (o, visited) {
      var type = _.util.type(o)
      visited = visited || {}

      switch (type) {
      case 'Object':
        if (visited[_.util.objId(o)]) {
          return visited[_.util.objId(o)]
        }
        var clone = {}
        visited[_.util.objId(o)] = clone

        for (var key in o) {
          if (o.hasOwnProperty(key)) {
            clone[key] = _.util.clone(o[key], visited)
          }
        }

        return clone

      case 'Array':
        if (visited[_.util.objId(o)]) {
          return visited[_.util.objId(o)]
        }
        var clone = []
        visited[_.util.objId(o)] = clone

        o.forEach(function (v, i) {
          clone[i] = _.util.clone(v, visited)
        })

        return clone
      }

      return o
    }
  },

  languages: {
    extend: function (id, redef) {
      var lang = _.util.clone(_.languages[id])

      for (var key in redef) {
        lang[key] = redef[key]
      }

      return lang
    },

    /**
		 * Insert a token before another token in a language literal
		 * As this needs to recreate the object (we cannot actually insert before keys in object literals),
		 * we cannot just provide an object, we need an object and a key.
		 * @param inside The key (or language id) of the parent
		 * @param before The key to insert before.
		 * @param insert Object with the key/value pairs to insert
		 * @param root The object that contains `inside`. If equal to Prism.languages, it can be omitted.
		 */
    insertBefore: function (inside, before, insert, root) {
      root = root || _.languages
      var grammar = root[inside]
      var ret = {}

      for (var token in grammar) {
        if (grammar.hasOwnProperty(token)) {

          if (token == before) {
            for (var newToken in insert) {
              if (insert.hasOwnProperty(newToken)) {
                ret[newToken] = insert[newToken]
              }
            }
          }

          // Do not insert token which also occur in insert. See #1525
          if (!insert.hasOwnProperty(token)) {
            ret[token] = grammar[token]
          }
        }
      }

      var old = root[inside]
      root[inside] = ret

      // Update references in other language definitions
      _.languages.DFS(_.languages, function(key, value) {
        if (value === old && key != inside) {
          this[key] = ret
        }
      })

      return ret
    },

    // Traverse a language definition with Depth First Search
    DFS: function(o, callback, type, visited) {
      visited = visited || {}
      for (var i in o) {
        if (o.hasOwnProperty(i)) {
          callback.call(o, i, o[i], type || i)

          if (_.util.type(o[i]) === 'Object' && !visited[_.util.objId(o[i])]) {
            visited[_.util.objId(o[i])] = true
            _.languages.DFS(o[i], callback, null, visited)
          }
          else if (_.util.type(o[i]) === 'Array' && !visited[_.util.objId(o[i])]) {
            visited[_.util.objId(o[i])] = true
            _.languages.DFS(o[i], callback, i, visited)
          }
        }
      }
    }
  },
  plugins: {},

  highlightAll: function(async, callback) {
    _.highlightAllUnder(document, async, callback)
  },

  highlightAllUnder: function(container, async, callback) {
    var env = {
      callback: callback,
      selector: 'code[class*="language-"], [class*="language-"] code, code[class*="lang-"], [class*="lang-"] code'
    }

    _.hooks.run("before-highlightall", env)

    var elements = env.elements || container.querySelectorAll(env.selector)

    for (var i=0, element; element = elements[i++];) {
      _.highlightElement(element, async === true, env.callback)
    }
  },

  highlightElement: function(element, async, callback) {
    // Find language
    var language, grammar, parent = element

    while (parent && !lang.test(parent.className)) {
      parent = parent.parentNode
    }

    if (parent) {
      language = (parent.className.match(lang) || [, ''])[1].toLowerCase()
      grammar = _.languages[language]
    }

    // Set language on the element, if not present
    element.className = element.className.replace(lang, '').replace(/\s+/g, ' ') + ' language-' + language

    if (element.parentNode) {
      // Set language on the parent, for styling
      parent = element.parentNode

      if (/pre/i.test(parent.nodeName)) {
        parent.className = parent.className.replace(lang, '').replace(/\s+/g, ' ') + ' language-' + language
      }
    }

    var code = element.textContent

    var env = {
      element: element,
      language: language,
      grammar: grammar,
      code: code
    }

    var insertHighlightedCode = function (highlightedCode) {
      env.highlightedCode = highlightedCode

      _.hooks.run('before-insert', env)

      env.element.innerHTML = env.highlightedCode

      _.hooks.run('after-highlight', env)
      _.hooks.run('complete', env)
      callback && callback.call(env.element)
    }

    _.hooks.run('before-sanity-check', env)

    if (!env.code) {
      _.hooks.run('complete', env)
      return
    }

    _.hooks.run('before-highlight', env)

    if (!env.grammar) {
      insertHighlightedCode(_.util.encode(env.code))
      return
    }

    insertHighlightedCode(_.highlight(env.code, env.grammar, env.language))
  },

  highlight: function (text, grammar, language) {
    var env = {
      code: text,
      grammar: grammar,
      language: language
    }
    _.hooks.run('before-tokenize', env)
    env.tokens = _.tokenize(env.code, env.grammar)
    _.hooks.run('after-tokenize', env)
    return Token.stringify(_.util.encode(env.tokens), env.language)
  },

  matchGrammar: function (text, strarr, grammar, index, startPos, oneshot, target) {
    var Token = _.Token

    for (var token in grammar) {
      if(!grammar.hasOwnProperty(token) || !grammar[token]) {
        continue
      }

      if (token == target) {
        return
      }

      var patterns = grammar[token]
      patterns = (_.util.type(patterns) === "Array") ? patterns : [patterns]

      for (var j = 0; j < patterns.length; ++j) {
        var pattern = patterns[j],
          inside = pattern.inside,
          lookbehind = !!pattern.lookbehind,
          greedy = !!pattern.greedy,
          lookbehindLength = 0,
          alias = pattern.alias

        if (greedy && !pattern.pattern.global) {
          // Without the global flag, lastIndex won't work
          var flags = pattern.pattern.toString().match(/[imuy]*$/)[0]
          pattern.pattern = RegExp(pattern.pattern.source, flags + "g")
        }

        pattern = pattern.pattern || pattern

        // Don’t cache length as it changes during the loop
        for (var i = index, pos = startPos; i < strarr.length; pos += strarr[i].length, ++i) {

          var str = strarr[i]

          if (strarr.length > text.length) {
            // Something went terribly wrong, ABORT, ABORT!
            return
          }

          if (str instanceof Token) {
            continue
          }

          if (greedy && i != strarr.length - 1) {
            pattern.lastIndex = pos
            var match = pattern.exec(text)
            if (!match) {
              break
            }

            var from = match.index + (lookbehind ? match[1].length : 0),
						    to = match.index + match[0].length,
						    k = i,
						    p = pos

            for (var len = strarr.length; k < len && (p < to || (!strarr[k].type && !strarr[k - 1].greedy)); ++k) {
              p += strarr[k].length
              // Move the index i to the element in strarr that is closest to from
              if (from >= p) {
                ++i
                pos = p
              }
            }

            // If strarr[i] is a Token, then the match starts inside another Token, which is invalid
            if (strarr[i] instanceof Token) {
              continue
            }

            // Number of tokens to delete and replace with the new match
            delNum = k - i
            str = text.slice(pos, p)
            match.index -= pos
          } else {
            pattern.lastIndex = 0

            var match = pattern.exec(str),
              delNum = 1
          }

          if (!match) {
            if (oneshot) {
              break
            }

            continue
          }

          if(lookbehind) {
            lookbehindLength = match[1] ? match[1].length : 0
          }

          var from = match.index + lookbehindLength,
					    match = match[0].slice(lookbehindLength),
					    to = from + match.length,
					    before = str.slice(0, from),
					    after = str.slice(to)

          var args = [i, delNum]

          if (before) {
            ++i
            pos += before.length
            args.push(before)
          }

          var wrapped = new Token(token, inside? _.tokenize(match, inside) : match, alias, match, greedy)

          args.push(wrapped)

          if (after) {
            args.push(after)
          }

          Array.prototype.splice.apply(strarr, args)

          if (delNum != 1)
            _.matchGrammar(text, strarr, grammar, i, pos, true, token)

          if (oneshot)
            break
        }
      }
    }
  },

  tokenize: function(text, grammar) {
    var strarr = [text]

    var rest = grammar.rest

    if (rest) {
      for (var token in rest) {
        grammar[token] = rest[token]
      }

      delete grammar.rest
    }

    _.matchGrammar(text, strarr, grammar, 0, 0, false)

    return strarr
  },

  hooks: {
    all: {},

    add: function (name, callback) {
      var hooks = _.hooks.all

      hooks[name] = hooks[name] || []

      hooks[name].push(callback)
    },

    run: function (name, env) {
      var callbacks = _.hooks.all[name]

      if (!callbacks || !callbacks.length) {
        return
      }

      for (var i=0, callback; callback = callbacks[i++];) {
        callback(env)
      }
    }
  }
}

var Token = _.Token = function(type, content, alias, matchedStr, greedy) {
  this.type = type
  this.content = content
  this.alias = alias
  // Copy of the full string this token was created from
  this.length = (matchedStr || "").length|0
  this.greedy = !!greedy
}

Token.stringify = function(o, language, parent) {
  if (typeof o == 'string') {
    return o
  }

  if (_.util.type(o) === 'Array') {
    return o.map(function(element) {
      return Token.stringify(element, language, o)
    }).join('')
  }

  var env = {
    type: o.type,
    content: Token.stringify(o.content, language, parent),
    tag: 'span',
    classes: ['token', o.type],
    attributes: {},
    language: language,
    parent: parent
  }

  if (o.alias) {
    var aliases = _.util.type(o.alias) === 'Array' ? o.alias : [o.alias]
    Array.prototype.push.apply(env.classes, aliases)
  }

  _.hooks.run('wrap', env)

  var attributes = Object.keys(env.attributes).map(function(name) {
    return name + '="' + (env.attributes[name] || '').replace(/"/g, '&quot;') + '"'
  }).join(' ')

  return '<' + env.tag + ' class="' + env.classes.join(' ') + '"' + (attributes ? ' ' + attributes : '') + '>' + env.content + '</' + env.tag + '>'

}

addLanguages(Prism)

export default Prism
