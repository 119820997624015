import { withRouteData } from '@mna/react'
import convert from '@mna/htmr'

const Index = ({ state, actions, routeName, routeData }) => {

  const data = routeData || {}
  const { html  = '' } = data

  const Item = data.Item = data.Item || (() => convert(html))

  return (
    <div className="article-width">
      <Item />
    </div>
  )
}

export default withRouteData({
  getRouteData: ({ api, routeName, isServer }) => api({
    type: 'action',
    action: 'getRouteData',
    data: { routeName, isServer }
  })
})(Index)
